import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Intro from '../../components/IntroHome';
import Helmet from 'react-helmet';
import styles from '../../styles/styles.json';
import styled from 'styled-components';

const ProjectsList = styled.div`
  background: white;
  border-bottom: 1px solid ${styles.colors.quiet.light};
  margin: 0 auto ;
  // padding-bottom: ${styles.spaces.large};
  padding-right: ${styles.spaces.xLarge};
  padding-left: ${styles.spaces.xLarge};
  @media ${styles.breakpoints.tabletPortrait} {
    // margin: ${styles.spaces.small} auto ${styles.spaces.medium} auto;
    padding-right: ${styles.spaces.large};
    padding-left: ${styles.spaces.large};
  }
  @media ${styles.breakpoints.mobile} {
    // margin: ${styles.spaces.small} auto ${styles.spaces.small} auto;
    padding-right: ${styles.spaces.medium};
    padding-left: ${styles.spaces.medium};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: left;
  justify-content: left;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    flex-direction: column;
  }
`;

const Info = styled.div`
  flex-basis : 60%;
  padding-top: ${styles.spaces.xLarge};
  padding-bottom: ${styles.spaces.xLarge};
  padding-right: ${styles.spaces.xLarge};
  padding-left: 0;
  @media ${styles.breakpoints.tabletPortrait} {
    padding-top: ${styles.spaces.small};
    padding-bottom: ${styles.spaces.small};
    padding-right: 0;
    padding-left: 0;
    flex-basis : 50%;
    margin: 0 auto;
    text-align: left;
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: ${styles.spaces.xSmall};
    padding-left: ${styles.spaces.xSmall};
  }
`;

const Picture = styled.img`
  border-radius: 8px;
  & :hover {
    transform: scale(1.02);
    transition: 0.15s ease-out;
  }
`;
const ImageWrap = styled.div`
  flex-basis : 40%;
  display: block;
  align-self: center;
  margin-bottom: 0;
  padding-bottom: 0;
  max-width: 580px;
  transition: transform .15s ease-out;
  @media ${styles.breakpoints.tabletPortrait} {
    padding-bottom: ${styles.spaces.xLarge};
    flex-basis : 50%;
    margin: 0 auto;
    max-width: 100%;
  }  
  &:hover {
    transform: scale(1.02);
  }
`;

const Title = styled.h2`
  font-family: ${styles.fontFamilies.system};
  font-size: ${styles.fontSizes.xxLarge};
  font-weight: ${styles.fontWeights.semiBold};
  margin-top: ${styles.spaces.medium};
  margin-bottom: ${styles.spaces.small};
  color: ${styles.colors.ash.base};
  line-height: 1.2;
  & a {
    color: ${styles.colors.ash.base};
  }
  & a:hover {
    color: ${styles.colors.primary.base};
  }
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xLarge};
  }
`;

const When = styled.h3`
  font-size: ${styles.fontSizes.xSmall};
  font-weight: ${styles.fontWeights.regular};
  margin-bottom: ${styles.spaces.medium};
  color: ${styles.colors.quiet.dark};
  text-transform: uppercase;
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xxSmall};
  }
`;

const Excerpt = styled.p`
  font-size: ${styles.fontSizes.small};
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.small};
    display: none;
  }
`;

const Button = styled.a`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 10px 56px 14px 20px;
  margin-bottom: ${styles.spaces.medium};
  border-radius: 30px;
  color: ${styles.colors.white};
  background: ${styles.colors.ash.base};
  font-weight: 600;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,.3);
  box-shadow: inset 0 -2px 0 rgba(0,0,0,.3);
  &:hover {
    background: ${styles.colors.primary.base};
    color: ${styles.colors.white};
    cursor: pointer;
  }
  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-size: ${styles.fontSizes.small};
    position: absolute;
    right: 25px;
    padding: 5px 1px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  &:hover:before {
    transform: translateX(4px);
  }
`;

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <div>
          <Helmet title={`Blog | ${data.site.siteMetadata.title}`}
            bodyAttributes={{ class: 'blog' }}
          />
          <Intro title='Blog'>
            <p>Take a peek at my latest posts</p>
          </Intro>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.frontmatter.path
            return (
              <ProjectsList>
                <div className="notch">
                  <Container>
                    <Info>
                      <Title><Link to={node.frontmatter.path}>{title}</Link></Title>
                      <When>{node.frontmatter.date}</When>
                      <Excerpt>{node.frontmatter.excerpt}</Excerpt>
                    </Info>
                    <ImageWrap>
                      <Link to={node.frontmatter.path}>
                        <Picture src={node.frontmatter.featuredImage.childImageSharp.sizes.src} alt='Niro logo' />
                      </Link>
                    </ImageWrap>
                  </Container>
                </div>
              </ProjectsList>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            description
            featuredImage {
              childImageSharp{
                sizes(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpSizes
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`











